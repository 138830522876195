ion-searchbar{
  background: rgba(255, 255, 255, 0.08);
  color: #C4C4CC;
}

.searchbar-input-container{
  --background: none;
}

.searchbar-search-icon.sc-ion-searchbar-ios{
  color: #FFF;
}
