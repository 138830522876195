
.toolbar{
  height: 0;
  overflow: visible;
  position: relative;
  ion-buttons{
    max-width: 100%;
    display: inline-block;
    position: absolute;
    top: 16px;
    left: 16px;
  }
}
