/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@media (min-width: 992px) and (orientation: landscape) {
  ion-app {
    border: 2px solid #1f1f1f;
    border-radius: 15px;
  }
  ion-popover {
    margin-left: calc(250px - 50vw) !important;
    margin-top: calc(450px - 50vh) !important;
  }
  .app-wrapper {
    max-height: 900px;
    max-width: 500px;
    @media (min-height: 900px) {
      margin: calc(50vh - 450px) calc(50vw - 250px);
    }
    @media (max-height: 899px) {
      margin: 0 calc(50vw - 250px);
    }
  }
}

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "theme/elements";
@import "theme/font";
@import "~swiper/swiper-bundle.min.css";
