@import "../variables";

@media (max-height: 600px) {
  .onboarding-wrapper {
    .onboarding-slide {
      .image {
        display: none;
      }
      .content {
        color: black !important;
        display: flex !important;
        justify-content: center !important;
        height: 100% !important;
      }
    }
  }
}

.onboarding-wrapper {
  height: 100%;
  .onboarding-slide {
    height: 100%;
    font-family: RedHatDisplayBold, serif;
    font-size: 28px;
    text-align: center;
    .image {
      height: 72%;
      color: #171620;
      padding-top: 20vh;
      min-width: 50%;
      max-width: 75%;
      margin-right: auto;
      margin-left: auto;
    }
    .content {
      min-height: 150px;
      height: 23%;
      color: #F2F2F7;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    .info-text {
      font-size: 17px;
    }
  }
  .onboarding-slide.first-slide {
    @media (min-height: 900px) {
      background: url("../../assets/backgrounds/onboarding/schoen-dass-du-da-bist.png") 0 0 / 100% no-repeat;
    }
    @media (max-height: 899px) {
      background: url("../../assets/backgrounds/onboarding/schoen-dass-du-da-bist.png") 0 calc(100% - 30vh) / 100% no-repeat;
    }
    .content {
      padding-top: 10vh;
    }
  }
  .onboarding-slide.second-slide {
    @media (min-height: 900px) {
      background: url("../../assets/backgrounds/onboarding/hier-koennen-wir-feiern.png") 0 0 / 100% no-repeat;
    }
    @media (max-height: 899px) {
      background: url("../../assets/backgrounds/onboarding/hier-koennen-wir-feiern.png") 0 calc(100% - 30vh) / 100% no-repeat;
    }
  }
  .onboarding-slide.third-slide {
    @media (min-height: 900px) {
      background: url("../../assets/backgrounds/onboarding/juhu.png") 0 0 / 100% no-repeat;
    }
    @media (max-height: 899px) {
      background: url("../../assets/backgrounds/onboarding/juhu.png") 0 calc(100% - 30vh) / 100% no-repeat;
    }
  }
}

.onboarding-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 15px;
  color: #F2F2F7;
  min-height: 44px;
}

.BtnForward{
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 35px;
  height: 35px;
  background-color: $primaryColor;
}

.navigation-footer {
  position: absolute;
  bottom: 0;
  .navigation-btn {
    height: 50px;
  }
}

.black-shadow {
  text-shadow: 0 0 3px #000;
}

.white-shadow {
  text-shadow: 0 0 3px #ffffff;
}


