.slider-card-tiny {
  background-size: cover;
  background-position: center;
  height: 200px;
  margin-right: 0;



  &.event{
    ion-text{
      color: #d0d0d0;
      text-shadow: #000 1px 0 10px;
    }
  }

  .slider-card-avatar {
    max-width: 30px;
    max-height: 30px;
    margin: 10px;
  }

  ion-card-title{
    font-size: 13px !important;
    font-weight: 300;
    word-break: break-word;
    text-shadow: #000 1px 0 6px;
  }

  ion-card-header{
    position: absolute;
    bottom: 0;
    padding: 10px 10px 10px;
  }
}

.split-slider-card {
  min-height: 200px;
  .split-slider-image {
    background-size: cover;
    background-position: center;
    height: 135px;
  }
}
