ion-card {
  border-radius: 16px;

  &.life-hack-box{
    ion-card-content{
      position: inherit;
      ion-icon{
        position: absolute;
        bottom: 16px;
        right: 16px;
      }
    }
  }
}

ion-card.staticBox{
  padding: 22px;
  position: relative;

  ion-card-header{
    padding: 0;

    ion-card-title {
      font-weight: 900;
      font-size: 28px !important;
      margin-top: 0 !important;
      line-height: 1.3;
    }

    ion-card-subtitle {
      color: white;
      font-size: 14px;
      margin-top: 11px;
    }
  }

  ion-button {
    margin-top: 26px;
    color: white;
    text-transform: none;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 13px; // this fixes the offset of the "hitbox" of the button due to padding in ion-button::part(native)
  }

  ion-button::part(native) {
    border-radius: 16px;
    padding: 24px 20px;
  }

  ion-card-content{
    padding: 0;
  }
}

.split-slider-card{
  &.facilities{
    ion-card-header{
      padding: 10px;
    }
    ion-card-content{
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.card-bg-cover {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 50%;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9735632184) 0%, rgba(0, 0, 0, 0.3655172414) 65%, rgba(0, 0, 0, 0) 100%);
}
