.link-to-area-map-box {
  --background: url("../../assets/backgrounds/erkunde-deine-gegend.png") 0 0/100% 100% no-repeat;
  //height: 207px; // 335 x 186
  width: calc(100% - 32px);
}

.link-to-help-box {
  --background: url("../../assets/backgrounds/laufts-grad-nicht.png") 0 0/100% 100% no-repeat;
  //height: 207px; // 335 x 186
  width: calc(100% - 32px);
}

.life-hack-box {
  background: #2F2D3F;
  aspect-ratio : 1 / 1;
  display: flex;
}

