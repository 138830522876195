.app-header{
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  z-index: 10;
  color: white;

  .toolBar{
    position: absolute;
    top: 36px;
    right: 16px;


    ion-icon{
      width: 26px;
      height: 26px;
      margin-left: 10px;
      padding: 5px;
      color: white;
    }
  }
}

.back-button-wrapper {
  position: relative;

  ion-back-button {
    --background: transparent;
    --color: white;
    --ripple-color: white;
    &::after {
      content: "";
      position: absolute;
      background: #00000085;
      border-radius: 100%;
      height: 34px;
      width: 34px;
      top: 7px;
      left: 8px;
      z-index: -1;
    }
  }
}

ion-header.invisible-header {
  position: absolute;
  left: 0;
  right: 0;
  ion-toolbar {
    --background: none;
    ion-button {
      background: #00000085;
      border-radius: 100%;
      height: 34px;
      width: 34px;
      margin-left: 10px;
    }
    ion-icon {
      color: white;
    }
  }
  backdrop-filter: blur(.3px);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
ion-header.invisible-header.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

ion-header.invisible-header::after {
  background-image: none !important;
}

.no-toolbar-spacer {
  height: 46px;
}
