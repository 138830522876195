@font-face {
  font-family: 'DM Sans';
  src:  url('font/DMSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'DM Sans Bold';
  src:  url('font/DMSans-Bold.ttf') format('truetype');
}

:root {
  --ion-font-family: 'DM Sans';
}
