ion-text {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.medium-headline {
  margin-bottom: 26px;
  margin-left: 20px;
  font-size: 20px;
}

.large-headline {
  margin-bottom: 26px;
  margin-left: 20px;
  font-weight: 900;
  font-size: 28px !important;
  line-height: 1.3;
}
